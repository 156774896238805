import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarErrorComponent } from '../components/shared/snack-bar/snack-bar-error/snack-bar-error.component';
import {IErrorResponse} from '../interfaces/error-response';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private matSnackBar: MatSnackBar
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<string>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const splitUrl = this.router.url.split('/');
        const homeUrl = `/${splitUrl[1]}`;
        const errorResponse: IErrorResponse = error.error;
        if (
          (homeUrl.includes('sign-in') && error.status === 400 || homeUrl.includes('sign-in') && error.status === 404) ||
          homeUrl.includes('sign-in') && error.status === 422
        ) {
          this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
            data: errorResponse.uiMessage,
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
        }

        if (!req.url.includes('sign-in') && error.status === 400) {
          this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
            data: errorResponse.uiMessage,
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
        }


        if (error.status === 404) {
          this.router.navigate(['error/not-found']);
        }

        if (error.status === 500 || error.status === 0) {
          this.router.navigate(['error/internal-error']);
        }

        return throwError(error);
      })
    );
  }
}
