import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './guard/auth.guard';
import {SignInComponent} from './components/core/sign-in/sign-in.component';
import {ForgotPasswordComponent} from './components/core/forgot-password/forgot-password.component';
import {ForgotPasswordGuard} from './guard/forgot-password.guard';
import {ErrorComponent} from './components/shared/error/error.component';
import {EventComponent} from './components/public/event/event.component';
import {RedirectFrontGuard} from './guard/redirect-front.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [RedirectFrontGuard],
    component: RedirectFrontGuard
  },
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: 'reset',
    component: ForgotPasswordComponent,
    canActivate: [ForgotPasswordGuard]
  },
  {
    path: 'admin',
    loadChildren: './modules/admin/admin.module#AdminModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:slug',
    component: EventComponent,
  },
  {
    path: 'error/:typeError',
    component: ErrorComponent,
  },
  {
    path: '**',
    redirectTo: 'error/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
