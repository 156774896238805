import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarSuccessComponent} from '../../shared/snack-bar/snack-bar-success/snack-bar-success.component';
import {AccountService} from '../../../services/account/account.service';
import {IForgotRequest} from '../../../interfaces/forgot-request';
import {switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isLoading: boolean;
  hide: boolean;
  forgotForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private enableRoute: ActivatedRoute,
    private accountService: AccountService
  ) {
    this.hide = true;
  }

  ngOnInit() {
    this.checkForgotForm();
  }

  // Check forgot password form
  checkForgotForm() {
    this.forgotForm = this.formBuilder.group({
      password: [null, [
        Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{5,}$')
      ]],
      confirmPassword: [null, Validators.required]
    });
  }

  // intercept event submit forgot form
  onSubmitForgotForm() {
    if (this.forgotForm.invalid) {
      return;
    }

    if (this.forgotForm.value.password !== this.forgotForm.value.confirmPassword) {
      this.forgotForm.get('confirmPassword').setErrors([{confirmPasswordError: true}]);
      return;
    }
    const forgotForm = this.forgotForm.value;
    const forgotReq: IForgotRequest = {
      idUser: '',
      token: '',
      password: forgotForm.password,
      confirmPassword: forgotForm.confirmPassword
    };
    this.resetForgotPassword(forgotReq);
  }

  // Reset forgot password
  resetForgotPassword(forgotReq: IForgotRequest) {
    const resetPasswordAccount$ = this.enableRoute.queryParams.pipe(
      tap(res => {forgotReq.idUser = res.userId; forgotReq.token = decodeURIComponent(res.token.replace(/ /gi, '+')); }),
      switchMap(param =>
        this.accountService.resetForgotPassword(forgotReq)
      )
    );

    resetPasswordAccount$.subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: `Password resettata con successo`,
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
      this.router.navigate(['sign-in']);
    }, error =>  {
      this.isLoading = false;
    });
  }

}
