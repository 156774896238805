import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {Observable} from "rxjs";
import {IDataTable} from "../../interfaces/data-table";
import {IFacility} from "../../interfaces/facility";

@Injectable({
  providedIn: 'root'
})
export class FacilityService {
  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootPOI;
  }

  getFacilities(pageNumber: number, pageSize: number, filter: string): Observable<IDataTable<IFacility>> {
    const parameters: string = `?facilitytype=Market&filter=${filter}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.httpClient.get<IDataTable<IFacility>>(`${this.url}facilities${parameters}`);
  }

  facilityDetails(idFacility: number): Observable<IFacility> {
    return this.httpClient.get<IFacility>(`${this.url}facility-details?idFacility=${idFacility}`);
  }

  facilityUpsert(facility: IFacility) {
    return this.httpClient.post(`${this.url}facility-upsert`, facility);
  }

  facilityDelete(idFacility: number) {
    return this.httpClient.delete(`${this.url}facility-delete?idFacility=${idFacility}`);
  }

}
