import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {EventFacilityService} from '../../../services/event-facility/event-facility.service';
import {IEventAssociate, IEventFacility} from '../../../interfaces/event-facility';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {MediaService} from '../../../services/media/media.service';
import {Observable, of} from 'rxjs';
import {AuthService} from '../../../services/auth/auth.service';
import {IAuthClientRequest} from '../../../interfaces/auth-request';
import {SnackBarSuccessComponent} from '../../shared/snack-bar/snack-bar-success/snack-bar-success.component';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarErrorComponent} from '../../shared/snack-bar/snack-bar-error/snack-bar-error.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MY_FORMATS} from '../../admin/award-components/edit-award/edit-award.component';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class EventComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean;
  isLoadingForm: boolean;
  currentLang: string;

  playStoreUrl = environment.playStoreUrl;
  appStoreUrl = environment.appStoreUrl;
  privacyUrl = environment.privacyUrl;

  event: IEventFacility;
  startTime = 0;
  expired: boolean;
  title: string;
  description: string;
  eventImage: any;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public sanitizer: DomSanitizer,
    private eventFacilityService: EventFacilityService,
    private mediaService: MediaService,
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    const eventSlug = this.route.snapshot.paramMap.get('slug');
    this.currentLang = navigator.language;

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    this.isLoading = true;
    this.eventFacilityService.eventDetailsBySlug(eventSlug).pipe(
      tap(event => {
        this.event = event;
        this.expired = new Date(this.event.endDate) < now;

        const startDate = new Date(this.event.startDate);
        if (startDate > now) {
          this.startTime = Math.abs((startDate.getTime() - new Date().getTime()) / 1000);
        }

        let info = this.event.infos.find(i => i.languageCode === this.currentLang);
        if (!info) {
          info = this.event.infos ? this.event.infos[0] : undefined;
        }

        if (info) {
          this.title = info.title;
          this.description = info.description;
        }
      }),
      switchMap(event => this.downloadMedia(event)),
      finalize(() => this.isLoading = false)
    ).subscribe(img => {
      if (img) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.eventImage = reader.result;
        }, false);
        reader.readAsDataURL(img);
      }
    });

    this.initForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      username: [null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]],
      agree: [false, Validators.requiredTrue]
    });
  }

  resetStartTime(): void {
    this.startTime = 0;
  }

  // Download media from database
  downloadMedia(event: IEventFacility): Observable<Blob> {
    if (event.attachments) {
      return this.mediaService.downloadMediaEvent(this.event.attachments.id);
    }
    return of(undefined);
  }

  isUserAssociate(): boolean {
    return !!localStorage.getItem(`user_associated_event${this.event.id}`);
  }

  clearUserAssociate(): void {
    localStorage.removeItem(`user_associated_event${this.event.id}`);
  }

  onSubmit(): void {
    this.form.get('agree').markAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isLoadingForm = true;
    const authReq: IAuthClientRequest = {
      clientId: environment.clientId,
      clientSecret: environment.clientSecret,
      grantType: environment.grantTypeSignUp
    };

    const associate: IEventAssociate = {
      eventId: this.event.id,
      userEmail: this.form.value.email,
      userName: this.form.value.username,
    };
    this.authService.getClientCredential(authReq).pipe(
      switchMap(auth => this.eventFacilityService.eventAssociate(auth, associate)),
      finalize(() => this.isLoadingForm = false)
    ).subscribe(() => {
      this.form.reset();
      localStorage.setItem(`user_associated_event${this.event.id}`, 'true');
      this.translateService.get('events.event-associate-success').subscribe(message => {
        this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
          data: message,
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      });
    }, error => {
      if (error.status === 403) {
        this.translateService.get('generic-error').subscribe(message => {
          this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
            data: message,
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
        });
      }
    });
  }
}
