import { NgModule } from '@angular/core';
import {environment} from '../../../environments/environment';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpBackend} from '@angular/common/http';
import {HttpTokenInterceptor} from '../../interceptors/http-token-interceptor';
import {HttpErrorInterceptor} from '../../interceptors/http-error-interceptor';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SnackBarSuccessComponent } from '../../components/shared/snack-bar/snack-bar-success/snack-bar-success.component';
import { SnackBarInfoComponent } from '../../components/shared/snack-bar/snack-bar-info/snack-bar-info.component';
import { SnackBarErrorComponent } from '../../components/shared/snack-bar/snack-bar-error/snack-bar-error.component';
import { MainNavComponent } from '../../components/shared/main-nav/main-nav.component';
import { EditProfileComponent } from '../../components/shared/edit-profile/edit-profile.component';
import { EditPasswordComponent } from '../../components/shared/edit-password/edit-password.component';
import { ErrorComponent } from '../../components/shared/error/error.component';
import {UploadTransportComponent} from '../../components/shared/upload-transport/upload-transport.component';
import {FooterComponent} from '../../components/shared/footer/footer.component';


export function HttpLoaderFactory(http: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(http));
}

@NgModule({
  declarations: [
    SnackBarSuccessComponent,
    SnackBarInfoComponent,
    SnackBarErrorComponent,
    MainNavComponent,
    EditProfileComponent,
    EditPasswordComponent,
    ErrorComponent,
    UploadTransportComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
      defaultLanguage: environment.defaultLanguage
    })
  ],
  exports: [
    MaterialModule,
    TranslateModule,
    SnackBarSuccessComponent,
    SnackBarInfoComponent,
    SnackBarErrorComponent,
    MainNavComponent,
    EditProfileComponent,
    EditPasswordComponent,
    ErrorComponent,
    UploadTransportComponent,
    FooterComponent
  ],
  entryComponents: [
    SnackBarSuccessComponent,
    SnackBarErrorComponent,
    SnackBarInfoComponent,
    EditProfileComponent,
    EditPasswordComponent,
    UploadTransportComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    DatePipe
  ],
})
export class SharedModule { }
