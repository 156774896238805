import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl;
  }

  uploadMedia(section: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    // If add section Update HttpTokenInterceptor
    return this.httpClient.post(`${this.url}/uploadmedia?section=${section}`, formData, { responseType: 'text' });
  }

  downloadMedia(fileId: number) {
    return this.httpClient.get(`${this.url}/downloadmedia?fileId=${fileId}`, { responseType: 'blob' });
  }

  /**
   * Unprotected api to retrieve event image
   * @param fileId the image id
   */
  downloadMediaEvent(fileId: number) {
    return this.httpClient.get(`${this.url}/downloadmediaevent?fileId=${fileId}`, { responseType: 'blob' });
  }
}
