import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {Observable} from 'rxjs';
import {IDataTable} from '../../interfaces/data-table';
import {IAward, IAwardRequest} from '../../interfaces/award';
import { IAwardUpsert } from '../../interfaces/award-upsert';
import { IAwardDelete } from '../../interfaces/award-delete';
import {IAwardDisabled} from '../../interfaces/award-disabled';

@Injectable({
  providedIn: 'root'
})
export class AwardService {
  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootAward;
  }

  getAwardList(pageNumber: number, pageSize: number, filter: string): Observable<IDataTable<IAward>> {
    const parameters = `?filter=${filter}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.httpClient.get<IDataTable<IAward>>(`${this.url}list${parameters}`);
  }

  awardDetails(idAward: number): Observable<IAward> {
    return this.httpClient.get<IAward>(`${this.url}detail?awardId=${idAward}`);
  }

  upsertAward(awardUpsert: IAwardUpsert) {
    return this.httpClient.post(`${this.url}upsert`, awardUpsert);
  }

  disabledAward(awardDisabled: IAwardDisabled) {
    return this.httpClient.post(`${this.url}disabled`, awardDisabled);
  }

  deleteAward(awardDelete: IAwardDelete) {
    return this.httpClient.post(`${this.url}delete`, awardDelete);
  }

  requestList(pageNumber: number, pageSize: number, awardId: number, code: string): Observable<IDataTable<IAwardRequest>> {
    const parameters = `?award=${awardId}&code=${code}&pageNumber=${pageNumber}&pageSize=${pageSize}&returnAll=true`;
    return this.httpClient.get<IDataTable<IAwardRequest>>(`${this.url}requestlist${parameters}`);
  }

  uploadAwardCodeExcel(file: File, awardId: number): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('excelFile', file, file.name);
    return this.httpClient.post<void>(`${this.url}upload-awardcode-excel?IdAward=${awardId}`, formData);
  }
}
