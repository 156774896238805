import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver-es';
import * as XLSX from 'xlsx';
import * as JSZip from 'jszip';
import {IExcel} from '../../interfaces/excel';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(files: Array<IExcel>): void {
    const  zip = new JSZip();
    files.forEach(file => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(file.data);
      const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      zip.file(file.filename + EXCEL_EXTENSION, excelBuffer, {base64: true} );
    });
    zip.generateAsync({type: 'blob'}).then(((content) =>  {
      saveAs(content, 'Mezzi.zip');
    }));
  }

  public exportSingleExcelFile(file: IExcel): void {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(file.data);
      const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer],
        { type: 'application/vnd.ms-excel' });

      saveAs(blob, file.filename + EXCEL_EXTENSION);
  }
}
