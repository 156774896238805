import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-snack-bar-info',
  templateUrl: './snack-bar-info.component.html',
  styleUrls: ['./snack-bar-info.component.scss']
})
export class SnackBarInfoComponent {

  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarInfoComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }


}
