export const environment = {
  production: false,
  overridden: true,
  frontUrl: 'https://www.ustep.it/',
  cookieUrl: 'https://www.ustep.it/privacy-policy-e-cookies-mobile-app/',
  privacyUrl: 'https://www.ustep.it/privacy-policy-e-cookies-mobile-app/',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.uds.ustep&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  appStoreUrl: 'https://apple.co/3OAyDJn',
  clientId: 'b0aa12f88m285ef69c7bfewa845201698bq39741.ustep.backoffice',
  clientSecret: '2b36179f-753e-6905-d154-cc91c721a5b1',
  grantTypeSignIn: 'password',
  grantTypeSignUp: 'client_credentials',
  defaultLanguage: 'it',
  protocol: 'https://',
  mapbox: {
    access_token: 'pk.eyJ1IjoicnVsZXRlY2giLCJhIjoiY2sxdDFycG9sMDBsZzNoa2Jyc2syMXA5YyJ9.ZJt2xJzo0zn7KOalOoSQCQ',
    style: 'mapbox://styles/mapbox/streets-v11',
    rootGeocoding: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
    rootDirection: 'https://api.mapbox.com/directions/v5/mapbox/walking/'
  },
  apiBaseUrl: 'api.ustep.net-serv.it',
  rootAuth: '/Auth/',
  rootAccount: '/Account/',
  rootPOI: '/POI/',
  rootAward: '/Award/',
  rootRoute: '/Route/',
  rootRouteProperty: '/RouteProperty/',
  rootBonus: '/Bonus/',
  rootCategory: '/EndUserCategory/',
  rootSubscription: '/Subscription/',
  rootStatistics: '/Statistics/',
  rootMediaItem: '/MediaItem/',
  rootSteps: '/Steps/',
  rootCO2: '/CO2/',
  rootEvent: '/Event/'
};
