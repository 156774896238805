import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedirectFrontGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!isDevMode()) {
      window.location.href = environment.frontUrl;
    } else {
      this.router.navigate(['sign-in']);
    }
    return true;
  }

}
