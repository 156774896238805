import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {EditProfileComponent} from '../edit-profile/edit-profile.component';
import {EditPasswordComponent} from '../edit-password/edit-password.component';
import {UploadTransportComponent} from '../upload-transport/upload-transport.component';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
  username: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getUsername();
  }

  // Get username from storage
  getUsername() {
    const localUsername = localStorage.getItem('username');
    this.username = localUsername.toString();
  }

  // Upload transport
  uploadTransport() {
   this.dialog.open(UploadTransportComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: false
    });
  }

  // Edit profile of user
  editProfile() {
    const dialogRef = this.dialog.open(EditProfileComponent, {
      width: '600px',
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      result !== 'cancel' ? this.getUsername() : null;
    });
  }

  // Edit Password of user
  editPassword() {
    const dialogRef = this.dialog.open(EditPasswordComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  // Sign out
  signOut() {
    localStorage.removeItem('accountToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('loginDate');
    localStorage.removeItem('user');
    localStorage.removeItem('username');
    this.router.navigate(['/sign-in']);
  }
}
