import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {Observable} from "rxjs";
import {IDataTable} from "../../interfaces/data-table";
import {IPoiMap} from "../../interfaces/poi-map";
import {IPoiUpsert} from "../../interfaces/poi-upsert";
import { IPoi } from '../../interfaces/poi';
import { IPoiAssociateUpsert } from '../../interfaces/poi-associate-upsert';
import { IPoiAssociate } from '../../interfaces/poi-associate';

@Injectable({
  providedIn: 'root'
})
export class PoiService {
  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootPOI;
  }

  getPoiCity(pageNumber: number, pageSize: number, geographicArea: string): Observable<IDataTable<IPoiMap>> {
    if (geographicArea) {
      const parameters: string = `?geographicArea=${geographicArea}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
      return this.httpClient.get<IDataTable<IPoiMap>>(`${this.url}table${parameters}`);
    } else {
      const parameters: string = `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      return this.httpClient.get<IDataTable<IPoiMap>>(`${this.url}table${parameters}`);
    }
  }

  poiDetails(idPoi: number): Observable<IPoi> {
    return this.httpClient.get<IPoi>(`${this.url}detailpoi?IdPoi=${idPoi}`);
  }

  upsertPoi(poi: IPoiUpsert): Observable<IPoiMap> {
    return this.httpClient.post<IPoiMap>(`${this.url}upserttouristpoi`, poi);
  }

  deletePoi(idPoi: number) {
    return this.httpClient.delete(`${this.url}deletepoi?IdPoi=${idPoi}`);
  }

  associationPoi(poiAssociateUpsert: IPoiAssociateUpsert) {
    return this.httpClient.post(`${this.url}upsertpoiassociation`, poiAssociateUpsert);
  }

  getpoiassociation(idFacility: number): Observable<IPoiAssociate> {
    return this.httpClient.get<IPoiAssociate>(`${this.url}getpoiassociation?poiId=${idFacility}`);
  }
}
