import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  typeError: string;
  isAuthUser: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    const error = this.route.snapshot.paramMap.get('typeError');
    if (error === 'not-found' || error === 'internal-error') {
      this.typeError = error;
    } else {
      this.typeError = 'not-found';
    }
    this.isAuthUser = !!localStorage.getItem('accountToken');
  }

  ngOnInit() {
  }

  goToHome() {
    if (this.isAuthUser) {
      this.router.navigate(['admin/dashboard']);
    } else {
      window.location.href = environment.frontUrl;
    }
  }

}
