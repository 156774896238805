import {Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../shared/snack-bar/snack-bar-success/snack-bar-success.component';
import { TranslateService } from '@ngx-translate/core';
import { FacilityService } from '../../../../services/facility/facility.service';
import {PoiService} from '../../../../services/poi/poi.service';
import { CategoryService } from '../../../../services/category/category.service';
import { AwardService } from '../../../../services/award/award.service';
import { IFacility } from '../../../../interfaces/facility';
import { IAwardUpsert } from '../../../../interfaces/award-upsert';
import {IAward, IAwardRequest} from '../../../../interfaces/award';
import {finalize, map, switchMap, take} from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../../services/media/media.service';
import {MatTableDataSource} from '@angular/material/table';
import {IExcel} from '../../../../interfaces/excel';
import {ExcelService} from '../../../../services/excel/excel.service';
import {IDataTable} from '../../../../interfaces/data-table';
import {MatPaginator} from '@angular/material/paginator';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  }
};

@Component({
  selector: 'app-edit-award',
  templateUrl: './edit-award.component.html',
  styleUrls: ['./edit-award.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class EditAwardComponent implements OnInit {
  @ViewChild('poiSelect', {static: false}) private poiSelect: MatSelect;
  @ViewChild('allSelected', {static: false}) private allSelected: MatOption;
  isLoading: boolean;
  award: IAward;
  upsertITForm: FormGroup;
  upsertENForm: FormGroup;
  pageIndex = 0;
  pageSize = 10;
  hasNextPage: boolean;
  facilities: Array<IFacility> = [];
  categories: Array<any> = [];

  date: number;

  currentDate: Date = new Date();
  minDate: Date;
  minDateEnd: Date;
  minShowCaseEndDate: Date;
  maxShowCaseEndDate: Date;

  showCaseEnd = false;

  poiRelated: Array<any> = [];
  poiRelatedSelected: Array<number> = [];

  validityList: Array<any> = [
    { label: '5 ore', value: 300 },
    { label: '10 ore', value: 600 },
    { label: '1 giorno', value: 1440 },
    { label: '1 settimana', value: 10080 },
    { label: '1 mese', value: 43800 }
  ];

  awardImage: any;
  currentImage: any;
  fileName: string;

  pageCodeIndex = 0;
  pageCodeSize = 10;
  filterCode = '';
  displayedCodesColumns = ['code', 'isCollected', 'timeLimit'];
  @ViewChild(MatPaginator, {static: true}) paginatorCodes: MatPaginator;
  dataSourceCodes: MatTableDataSource<IAwardRequest>;
  awardCodeDataTable: IDataTable<IAwardRequest>;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private facilityService: FacilityService,
    private poiService: PoiService,
    private categoryService: CategoryService,
    private mediaService: MediaService,
    private awardService: AwardService,
    private excelService: ExcelService
  ) {
    this.minDate = new Date(this.currentDate.getFullYear(), this.currentDate.getUTCMonth(), this.currentDate.getUTCDate() + 1);
    this.minDateEnd = new Date(this.minDate.getFullYear(), this.minDate.getUTCMonth(), this.minDate.getDate());
    this.dataSourceCodes = new MatTableDataSource<IAwardRequest>();
  }

  ngOnInit() {
    this.getPartner();
    this.getCategories();
    this.initForms();
    this.dataSourceCodes.paginator = this.paginatorCodes;
  }

  // Get facilities from database
  getPartner() {
    this.isLoading = true;
    this.facilityService.getFacilities(this.pageIndex, this.pageSize, '').subscribe(res => {
      this.isLoading = false;
      this.hasNextPage = res.hasNextPage;
      res.result.forEach(facility => this.facilities.push(facility));
    }, error => this.isLoading = false);
  }

  // Get next facilities from database
  getNextPartner() {
    if (this.hasNextPage) {
      this.pageIndex += 1;
      this.pageSize = 10;
      this.getPartner();
    }
  }

  // Get categories from database
  getCategories() {
    this.categories.push({ label: 'Tutti', value: [] });
    this.categoryService.getCategoryList(0, 500, '').pipe(
      take(1),
      map(res => res.result)
    ).subscribe(categories => {
      categories.forEach(category => {
        this.categories.push({ label: category.langInfos[0].name, value: [category.id] });
      });
    });
  }

  // Initialize forms
  initForms() {
    this.isLoading = true;
    const idAward = this.route.snapshot.paramMap.get('id');
    this.awardService.awardDetails(Number(idAward)).subscribe(res => {
      this.award = res;
      this.showCaseEnd = this.award.showcaseEnd && this.compareDate(this.award.showcaseEnd) ? true : false;

      this.award.relateds.forEach(related => {
        this.poiRelatedSelected.push(related.id);
      });

      this.upsertITForm = this.formBuilder.group({
        title: [null, Validators.required],
        description: [null, Validators.required],
        partner: [this.award.partner.id, Validators.required],
        related: [this.poiRelatedSelected],
        stepcoin: [this.award.price, [Validators.required, Validators.pattern('^[0-9]+$')]],
        start: [this.award.start, Validators.required],
        end: [this.award.end, Validators.required],
        leftTimeMin: [this.award.leftTimeMin, Validators.required],
        categories: [this.award.endUserCategories.length ? this.categories[this.getCategoriesSelected()].value : this.categories[0].value],
        suggested: [this.award.showcaseEnd && this.compareDate(this.award.showcaseEnd) ? true : false],
        exclusives: [!!this.award.exclusives.length],
        termsOfUse: [null, Validators.required],
        showcaseEnd: [this.award.showcaseEnd && this.compareDate(this.award.showcaseEnd) ? this.award.showcaseEnd : null]
      });

      this.upsertENForm = this.formBuilder.group({
        title: [null, Validators.required],
        description: [null, Validators.required],
        partner: [this.award.partner.id, Validators.required],
        related: [this.poiRelatedSelected],
        stepcoin: [this.award.price, [Validators.required, Validators.pattern('^[0-9]+$')]],
        start: [this.award.start, Validators.required],
        end: [this.award.end, Validators.required],
        leftTimeMin: [this.award.leftTimeMin, Validators.required],
        categories: [this.award.endUserCategories.length ? this.categories[this.getCategoriesSelected()].value : this.categories[0].value],
        suggested: [this.award.showcaseEnd && this.compareDate(this.award.showcaseEnd) ? true : false],
        exclusives: [!!this.award.exclusives.length],
        termsOfUse: [null, Validators.required],
        showcaseEnd: [this.award.showcaseEnd && this.compareDate(this.award.showcaseEnd) ? this.award.showcaseEnd : null]
      });
      this.changeValueForm();

      if (this.award.attachments.length) {
        this.downloadMedia(this.award.attachments[0].id);
      }
      this.isLoading = false;
      this.getCodes();
    }, error => this.isLoading = false);
  }

  // Download media from database
  downloadMedia(fileId: number) {
    this.isLoading = true;
    this.mediaService.downloadMedia(fileId).subscribe(res => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.currentImage = reader.result;
      }, false);
      if (res) { reader.readAsDataURL(res); }
      this.isLoading = false;
    }, error => this.isLoading = false);
  }

  // Upload image event
  fileChangeEvent(event: any) {
    if (event.target.files && event.target.files[0]) {

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => { this.awardImage = e.target.result; };
      const file: File = event.target.files[0];
      this.mediaService.uploadMedia('Award', file).subscribe(res => {
        this.fileName = res;
        this.currentImage = null;
      });
    }
  }

  // Check if award start is expired
  compareDate(start: string): boolean {
    const date: number = new Date().setHours(0, 0, 0, 0);
    const currentDate = new Date(date);
    const startDate = new Date(start);

    return startDate >= currentDate;
  }

  // Get category selected
  getCategoriesSelected(): number {
   return this.categories.findIndex(category => category.value[0] === this.award.endUserCategories[0].id);
  }

  // Set value to form property
  changeValueForm() {
    this.award.langInfos.forEach(info => {
      if (info.languageCode === 'it-IT') {
        this.upsertITForm.get('title').setValue(info.title);
        this.upsertITForm.get('description').setValue(info.description);
        this.upsertITForm.get('termsOfUse').setValue(info.termsOfUse);
      } else {
        this.upsertENForm.get('title').setValue(info.title);
        this.upsertENForm.get('description').setValue(info.description);
        this.upsertENForm.get('termsOfUse').setValue(info.termsOfUse);
      }
    });
    this.getChildPartner();
  }

  // Set value to form property
  changeValue(value: string, name: string, form: string) {
    if (form === 'IT') {
      this.upsertENForm.get(name).setValue(value);
    } else {
      this.upsertITForm.get(name).setValue(value);
    }
    if (name === 'partner') {
      this.getChildPartner();
    }
  }

  // Get related poi from database
  getChildPartner() {
    this.isLoading = true;
    const idPartner: number = this.upsertITForm.get('partner').value;
    this.poiService.getpoiassociation(idPartner).pipe(take(1)).subscribe(res => {
      this.isLoading = false;
      this.poiRelated = [];
      this.poiRelated.push({id: 0, name: 'Seleziona tutti'});
      res.relateds.forEach(value => {
        this.poiRelated.push({id: value.id, name: value.langInfos[0].name});
      });
    }, error =>  this.isLoading = false);
  }

  // Select all Poi from list
  selectAll() {
    if (this.allSelected.selected) {
      this.poiRelatedSelected = [];
      this.poiSelect.options.forEach((option: MatOption) => option.select());
      this.poiRelated.forEach(poi => this.poiRelatedSelected.push(poi.id));
    } else {
      this.poiSelect.options.forEach((option: MatOption) => option.deselect());
      this.poiRelatedSelected = [];
    }
  }

  // Set value to form property
  changePoiRelatedValue(value: Array<number>, name: string, form: string) {
    if (form === 'IT') {
      this.upsertENForm.get(name).setValue(value);
    } else {
      this.upsertITForm.get(name).setValue(value);
    }
  }

  // Select Poi from list
  selectPoi(idPoi) {
    if (this.poiRelatedSelected.length && this.poiRelatedSelected.includes(idPoi)) {
      this.poiRelatedSelected = this.poiRelatedSelected.filter(value => value !== idPoi);
    } else {
      this.poiRelatedSelected.push(idPoi);
    }
  }

  // Set value to form property
  changeStepcoin(event: Event, form: string) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (form === 'IT') {
      this.upsertENForm.get('stepcoin').setValue(filterValue);
    } else {
      this.upsertITForm.get('stepcoin').setValue(filterValue);
    }
  }

  // Set value to form property
  checkValue(value: boolean, name: string, form: string) {
    if (name === 'suggested') {
      this.showCaseEnd = value;
      if (!this.showCaseEnd) {
        this.upsertITForm.get('showcaseEnd').setErrors(null);
        this.upsertENForm.get('showcaseEnd').setErrors(null);
      }
    }

    if (form === 'IT') {
      this.upsertENForm.get(name).setValue(value);
    } else {
      this.upsertITForm.get(name).setValue(value);
    }
  }

  // Set value to form property
  changeDate(value: string, name: string, form: string) {
    if (form === 'IT') {
      this.upsertENForm.get(name).setValue(value);
    } else {
      this.upsertITForm.get(name).setValue(value);
    }

    if (name === 'end') {
      this.minShowCaseEndDate = new Date(this.upsertITForm.get('start').value);
      this.maxShowCaseEndDate = new Date(this.upsertITForm.get('end').value);
    }
  }

  // Check form on submit
  onSubmit() {
    const formIT = this.upsertITForm.value;
    const formEN = this.upsertENForm.value;
    const awardLangInfos: Array<any> = [];

    if (this.upsertITForm.invalid && this.upsertENForm.invalid) { return; }

  /*  if (this.poiRelated.length > 1 && !this.poiRelatedSelected.length) {
      this.upsertITForm.get('related').setErrors({relatedError: true});
      this.upsertENForm.get('related').setErrors({relatedError: true});
      return;
    }*/

    if (this.showCaseEnd && formIT.showcaseEnd == null) {
      formIT.showcaseEnd.setErrors({ showCaseEndError: true });
    }

    if (formIT.title && formIT.description && formIT.termsOfUse) {
      awardLangInfos.push({ title: formIT.title, description: formIT.description, termsOfUse: formIT.termsOfUse,  languageCode: 'it-IT' });
    }

    if (formEN.title && formEN.description && formEN.termsOfUse) {
      awardLangInfos.push({ title: formEN.title, description: formEN.description, termsOfUse: formEN.termsOfUse, languageCode: 'en-EN' });
    }

    let attachments: Array<any> = [];

    this.awardImage
      ?
      attachments.push({
        id: 0,
        fileName: this.fileName,
        type: 'Image',
        langInfos: [{ languageCode: 'it-iT', description: this.fileName }]
      })
      :
      attachments = this.award.attachments;


    const awardUpsert: IAwardUpsert = {
      routes: [],
      partner: formIT.partner,
      relateds: this.poiRelatedSelected.filter(id => id !== 0),
      exclusives: formIT.exclusives
        ? [{ id: this.award.exclusives.length ? this.award.exclusives[0].id : 0, owningEntity: formIT.partner }]
        : [],
      endUserCategories: formIT.categories,
      price: formIT.stepcoin,
      attachments,
      langInfos: awardLangInfos,
      start: formIT.start,
      end: formIT.end,
      id: this.award.id,
      disabled: false,
      showcaseEnd: formIT.suggested && formIT.showcaseEnd ? formIT.showcaseEnd : null,
      leftTimeMin: formIT.leftTimeMin
    };

    this.upsertAward(awardUpsert);
  }

  // Upsert award
  upsertAward(awardUpsert: IAwardUpsert) {
    this.isLoading = true;
    this.awardService.upsertAward(awardUpsert).subscribe(res => {
      this.isLoading = false;
      this.router.navigate(['/admin/award']);
      this.translateService.get('edit-award-success').subscribe(message => {
        this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
          data: message,
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      });
    }, error => this.isLoading = false);
  }

  /**
   * Create and download a template xlsx to create award codes
   */
  downloadCodesTemplate() {
    const awardCodesExcel: IExcel = {
      data: [{
        'Award Code': 'AX456H',
      }],
      filename: 'Award_Codes'
    };
    this.excelService.exportSingleExcelFile(awardCodesExcel);
  }

  /**
   * Upload the template xlsx to create award codes
   * @param event input file event
   */
  uploadCodexFile(event: any) {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    this.isLoading = true;
    const file = event.target.files[0];
    this.awardService.uploadAwardCodeExcel(file, this.award.id)
      .pipe(
        finalize(() => this.isLoading = false),
        switchMap(() => this.translateService.get('success-import'))
      )
      .subscribe(message => {
        this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
          data: message,
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
        this.filterCode = '';
        this.pageCodeIndex = 0;
        this.getCodes();
      });
  }

  /**
   * get the award codes
   */
  getCodes() {
    this.isLoading = true;
    this.awardService.requestList(this.pageCodeIndex, this.pageCodeSize, this.award.id, this.filterCode).pipe(
      take(1),
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.awardCodeDataTable = res;
      this.dataSourceCodes = new MatTableDataSource<IAwardRequest>(this.awardCodeDataTable.result);
    });
  }

  /**
   * Filter award code table by code
   * @param event input event
   */
  applyCodesFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.pageCodeIndex = 0;

    if (filterValue.length >= 3) {
      this.filterCode = filterValue;
      this.getCodes();
    }
    if (!filterValue.length) {
      this.filterCode = '';
      this.getCodes();
    }
  }

  // Pagination Table.
  changePageCodes(event) {
    this.pageCodeIndex = event.pageIndex;
    this.pageCodeSize = event.pageSize;
    this.getCodes();
  }
}
