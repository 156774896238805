import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {MaterialModule} from './modules/material/material.module';
import {SharedModule} from './modules/shared/shared.module';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SignInComponent} from './components/core/sign-in/sign-in.component';
import {ForgotPasswordComponent} from './components/core/forgot-password/forgot-password.component';
import {DatePipe} from '@angular/common';
import {EventComponent} from './components/public/event/event.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {RedirectFrontGuard} from './guard/redirect-front.guard';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import {environment} from '../environments/environment';
import {CdTimerModule} from 'angular-cd-timer';

// https://github.com/tinesoft/ngx-cookieconsent
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  position: 'top-right',
  theme: 'classic',
  palette: {
    popup: {
      background: '#9EC587',
      text: '#115942',
      link: '#115942'
    },
    button: {
      background: '#115942',
      text: '#fff',
      border: 'transparent'
    }
  },
  type: 'opt-out', // info
  content: {
    href: environment.cookieUrl
  }
};

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    ForgotPasswordComponent,
    EventComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    CdTimerModule
  ],
  entryComponents: [],
  providers: [DatePipe, RedirectFrontGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
}
