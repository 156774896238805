import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackBarSuccessComponent} from "../snack-bar/snack-bar-success/snack-bar-success.component";
import {SnackBarInfoComponent} from "../snack-bar/snack-bar-info/snack-bar-info.component";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../../services/auth/auth.service";
import {AccountService} from "../../../services/account/account.service";
import {IUser} from "../../../interfaces/user";
import {IUserUpdate} from "../../../interfaces/user-update";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  user: IUser;
  updateForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<EditProfileComponent>,
    private router: Router,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    private accountService: AccountService,
    private  translateService: TranslateService
  ) { }

  ngOnInit() {
    this.checkUpdateForm();
  }

  // Initialize form
  checkUpdateForm() {
    this.authService.getUser().subscribe(res => {
      this.user = res;
      this.updateForm = this.formBuilder.group({
        email: [res.email, [Validators.required, Validators.email]],
        firstName: [res.firstName],
        lastName: [res.lastName],
        username: [res.userName, Validators.required],
        phone: [res.phone]
      });
    }, error => this.closeDialog());
  }

  // Close dialog
  closeDialog() {
    this.dialogRef.close('cancel');
  }

  // Check form on submit
  OnSubmit() {
    if (this.updateForm.invalid) {
      return;
    }
    const updateFormValue = this.updateForm.value;

    const userUpdate: IUserUpdate =  {
      id: this.user.id,
      username: updateFormValue.username,
      email: updateFormValue.email,
      phoneNumber: updateFormValue.phone,
      firstName: updateFormValue.firstName,
      lastName: updateFormValue.lastName,
      birthDate: this.user.birthDate ? this.user.birthDate : '',
      profileImage: this.user.profileImage ? this.user.profileImage : ''
    };
    this.updateUser(userUpdate);
  }

  // Update user
  updateUser(userUpdate: IUserUpdate) {
    this.accountService.accountUpdate(userUpdate).subscribe(res => {
      localStorage.setItem('username', userUpdate.username);
      this.dialogRef.close('save');
      if (userUpdate.email !==  this.user.email) {
        this.router.navigate(['sign-in']);
        this.translateService.get('edit-email-info').subscribe(message => {
          this.matSnackBar.openFromComponent(SnackBarInfoComponent, {
            data: `${message} ${userUpdate.email}`,
            duration: 4000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
        });
      } else {
        this.translateService.get('edit-profile-success').subscribe(message => {
          this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
            data: message,
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
        });
      }
    }, error => this.closeDialog())
  }

}
