import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {IEventAssociate, IEventFacility} from '../../interfaces/event-facility';
import {IAuth} from '../../interfaces/auth';

@Injectable({
  providedIn: 'root'
})
export class EventFacilityService {
  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootEvent;
  }

  eventDetails(eventRef: number): Observable<IEventFacility> {
    return this.httpClient.get<IEventFacility>(`${this.url}event-details?idEvent=${eventRef}`);
  }

  eventDetailsBySlug(eventSlug: string): Observable<IEventFacility> {
    return this.httpClient.get<IEventFacility>(`${this.url}event-details-slug?slugUrl=${eventSlug}`);
  }

  eventUpsert(event: IEventFacility): Observable<IEventFacility> {
    return this.httpClient.post<IEventFacility>(`${this.url}event-upsert`, event);
  }

  eventClose(eventRef: number): Observable<IEventFacility> {
    return this.httpClient.put<IEventFacility>(`${this.url}event-close?idEvent=${eventRef}`, null);
  }

  eventAssociate(auth: IAuth, associate: IEventAssociate): Observable<any> {
    return this.httpClient.post<IEventFacility>(`${this.url}associate`, associate, {
      headers: {
        Authorization: `Bearer ${auth.token}`
      }
    });
  }

}
