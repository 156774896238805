import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {IForgotRequest} from '../../interfaces/forgot-request';
import {Observable} from "rxjs";
import {ICities} from "../../interfaces/cities";
import {IUserUpdate} from "../../interfaces/user-update";
import {IPasswordUpdate} from "../../interfaces/password-update";
import {IAccountAssociate} from "../../interfaces/account-associate";
import {IAccountAssociateFacility} from "../../interfaces/account-associate-facility";


@Injectable({
  providedIn: 'root'
})
export class AccountService {
  url: string;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootAccount;
  }

  forgotPassword(userEmail: string) {
    const body = { email: userEmail };
    return this.httpClient.post(this.url + 'forgot', body);
  }

  resetForgotPassword(forgotReq: IForgotRequest) {
    return this.httpClient.post(`${this.url}reset`, forgotReq, { responseType: 'text' } );
  }

  accountUpdate(userUpdate: IUserUpdate) {
    return this.httpClient.post(`${this.url}update`, userUpdate);
  }

  changePassword(passwordUpdate: IPasswordUpdate) {
    return this.httpClient.post(`${this.url}changepassword`, passwordUpdate);
  }

  getCitiesList(): Observable<ICities> {
    return this.httpClient.get<ICities>(`${this.url}listcities`);
  }

  facilityAssociation(facilityId: number): Observable<IAccountAssociate> {
    const url= `${this.url}getfacilityassociation?facilityId=${facilityId}`;
    return this.httpClient.get<IAccountAssociate>(url);
  }

  associateFacility(associateAccount: IAccountAssociateFacility): Observable<any> {
    return this.httpClient.post(`${this.url}associatefacility`, associateAccount);
  }

  dissociateFacility(associateAccount: IAccountAssociateFacility): Observable<any> {
    return this.httpClient.post(`${this.url}dissociatefacility`, associateAccount);
  }
}
