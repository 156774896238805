import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackBarSuccessComponent} from "../snack-bar/snack-bar-success/snack-bar-success.component";
import {TranslateService} from "@ngx-translate/core";
import {AccountService} from "../../../services/account/account.service";
import {IUser} from "../../../interfaces/user";
import {IPasswordUpdate} from "../../../interfaces/password-update";

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {
  hide: boolean;
  localUser: string = localStorage.getItem('user');
  user: IUser = JSON.parse(this.localUser);
  updateForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<EditPasswordComponent>,
    private router: Router,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private accountService: AccountService,
    private  translateService: TranslateService
  ) {
    this.hide = true;
  }

  ngOnInit() {
    this.checkUpdateForm();
  }

  checkUpdateForm() {
    this.updateForm = this.formBuilder.group({
      oldPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{5,}$')]],
      confirmPassword: [null, Validators.required]
    });
  }

  // Close dialog
  closeDialog() {
    this.dialogRef.close();
  }

  // Check form on submit
  onSubmit() {
    if (this.updateForm.invalid)
      return;

    const updateFormValue = this.updateForm.value;

    if (updateFormValue.newPassword !== updateFormValue.confirmPassword) {
      this.updateForm.get('confirmPassword').setErrors([{'confirmPasswordError': true}]);
      return;
    }

    const updatePassword: IPasswordUpdate = {
      id: this.user.id,
      oldPassword: updateFormValue.oldPassword,
      newPassword: updateFormValue.newPassword,
      confermaPassword: updateFormValue.confirmPassword
    };
    this.updatePassword(updatePassword);
  }

  // Update password
  updatePassword(updatePassword: IPasswordUpdate) {
    this.accountService.changePassword(updatePassword).subscribe(res => {
      this.closeDialog();
      this.translateService.get('edit-password-success').subscribe(message => {
        this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
          data: message,
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      });
    });
  }

}
