import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {CO2Service} from '../../../services/CO2/co2.service';
import {SnackBarSuccessComponent} from '../snack-bar/snack-bar-success/snack-bar-success.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarErrorComponent} from '../snack-bar/snack-bar-error/snack-bar-error.component';
import {ExcelService} from '../../../services/excel/excel.service';
import {IExcel} from '../../../interfaces/excel';

@Component({
  selector: 'app-upload-transport',
  templateUrl: './upload-transport.component.html',
  styleUrls: ['./upload-transport.component.scss']
})
export class UploadTransportComponent implements OnInit {
  isLoading: boolean;
  transportTypeList: Array<any>;
  typeSelected: string;
  constructor(
    private dialogRef: MatDialogRef<UploadTransportComponent>,
    private matSnackBar: MatSnackBar,
    private co2Service: CO2Service,
    private excelService: ExcelService
  ) {
    this.transportTypeList = [
      {label: 'Pubblici', value: 'Public'},
      {label: 'Privati', value: 'Private'},
    ];
    this.typeSelected = this.transportTypeList[0].value;
  }

  ngOnInit() {}

  selectType(type: string) {
    this.typeSelected = type;
  }

  // Close dialog
  closeDialog() {
    this.dialogRef.close();
  }

  downloadTemplate(): void {
    const files: Array<IExcel> = new Array<IExcel>();
    const privateTransportExcel: IExcel = {
      data: [{
        Categoria: 'Alfa Romeo',
        Modello: 'Brera',
        'Tipo di carburante': 'Benzina',
        'Delta  Consumo  [l/km]' : '0,114',
        'Delta Emissioni di CO2 [g/km]': '270',
        'Prezzo [euro/l]': '1,559',
        'Tipologia di mezzo': 'Auto'
      }],
      filename: `Mezzi_privati_${Date.now()}`
    };
    const publicTransportExcel: IExcel = {
      data: [{
        'Tipologia di mezzo': 'Autobus',
        'Delta Emissioni di CO2 [g/km]': '60',
      }],
      filename: `Mezzi_pubblici_${Date.now()}`
    };
    files.push(privateTransportExcel);
    files.push(publicTransportExcel);
    this.excelService.exportAsExcelFile(files);
  }

  fileChangeEvent(event: any) {
    if (event.target.files.length) {
      this.isLoading = true;
      const file = event.target.files[0];
      const formData: FormData = new FormData();
      formData.append('excelFile', file, file.name);
      this.co2Service.uploadTransporter(formData, this.typeSelected).subscribe(() => {
        this.isLoading = false;
        this.closeDialog();
        this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
          data: 'Importazione avvenuta con successo',
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      }, error => {
        this.isLoading = false;
        if (error.status === 404 || error.status === 500) {
          this.closeDialog();
        }
        this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
          data: 'Si è verificato un errore',
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      });
    }
  }


}
