import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {AuthService} from '../services/auth/auth.service';
import {catchError, tap, switchMap} from 'rxjs/operators';
import {IAuth} from '../interfaces/auth';
import {Router} from '@angular/router';

@Injectable()
export class HttpTokenInterceptor implements  HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqUrl = req.url;
    const baseUrlAPI = `${environment.protocol}${environment.apiBaseUrl}`;
    if (!reqUrl.includes('section=Geolocation') && !reqUrl.includes('section=Route') && !reqUrl.includes('section=Award')
      && !reqUrl.includes('section=Event') && !reqUrl.includes('upload-transport-excel') && !reqUrl.includes('upload-awardcode-excel')) {
      if (!req.headers.has('Content-Type')) { req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') }); }
    }

    if (reqUrl !== `${baseUrlAPI}/Auth/Token` && reqUrl !== `${baseUrlAPI}/Account/forgot` && reqUrl !== `${baseUrlAPI}/Account/reset`) {
      return next.handle(this.injectToken(req)).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 && !error.url.includes('RefreshToken') && localStorage.getItem('refreshToken')) {
            return this.authService.injectRefreshToken().pipe(
              tap((auth: IAuth) => {
                const currentDate = new Date().setSeconds(auth.expiresIn);
                localStorage.removeItem('accountToken');
                localStorage.setItem('accountToken', auth.token);
                localStorage.removeItem('refreshToken');
                localStorage.setItem('refreshToken', auth.refreshToken);
                localStorage.removeItem('loginDate');
                localStorage.setItem('loginDate', new Date(currentDate).toString());
              }),
              switchMap(() => next.handle(this.injectToken(req))),
            );
          }
          if (error.status === 401 && error.url.includes('RefreshToken')) {
            localStorage.clear();
            this.router.navigate(['/sign-in']);
          }
          return throwError(error);
        })
      );
    } else {
      return next.handle(req);
    }
  }

  injectToken(req: HttpRequest<any>) {
    const accessToken: string = localStorage.getItem('accountToken');
    if (accessToken && !req.headers.has('Authorization')) {
      return req.clone({
        headers: req.headers.set('Authorization', `Bearer ${accessToken}`)
      });
    }
    return req;
  }
}
