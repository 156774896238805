import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {Observable} from "rxjs";
import {IDataTable} from "../../interfaces/data-table";
import {ICategory} from "../../interfaces/category";
import {ICategoryUpsert} from "../../interfaces/category-upsert";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootCategory;
  }

  getCategoryList(pageNumber: number, pageSize: number, filter: string):Observable<IDataTable<ICategory>> {
    const parameters: string = `?filter=${filter}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.httpClient.get<IDataTable<ICategory>>(`${this.url}list${parameters}`);
  }

  categoryDetails(idCategory: number): Observable<ICategory> {
    return this.httpClient.get<ICategory>(`${this.url}detail?categoryId=${idCategory}`);
  }

  upsertCategory(categoryUpsert: ICategoryUpsert) {
    return this.httpClient.post(`${this.url}upsert`, categoryUpsert);
  }

  deleteCategory(idCategory: number) {
    return this.httpClient.delete(`${this.url}delete?categoryId=${idCategory}`);
  }
}
