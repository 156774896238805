import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CO2Service {
  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootCO2;
  }

  uploadTransporter(formData: FormData, domain: string) {
    return this.httpClient.post(`${this.url}upload-transport-excel?domain=${domain}`, formData);
  }
}
