import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarInfoComponent} from '../../shared/snack-bar/snack-bar-info/snack-bar-info.component';
import {AuthService} from '../../../services/auth/auth.service';
import {AccountService} from '../../../services/account/account.service';
import IAuthRequest from '../../../interfaces/auth-request';
import {IUser} from '../../../interfaces/user';
import {Observable} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  isLoading: boolean;
  hide: boolean;
  signInForm: FormGroup;
  forgotForm: FormGroup;
  forgotPassword = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private authService: AuthService,
    private accountService: AccountService
  ) {
    this.hide = true
  }

  ngOnInit() {
    this.checkSignInForm();
    this.checkForgotForm();
  }

  // Initialize form
  checkSignInForm() {
    this.signInForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });
  }

  // Initialize form
  checkForgotForm() {
    this.forgotForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  // Change form sign-in to send email
  changeForm(value: string) {
    value === 'forgot' ? this.forgotPassword = true : this.forgotPassword = false;
  }

  // Check form sign-in on submit
  onSubmitSignInForm() {
    if (this.signInForm.invalid) {
      return;
    }

    const signInForm = this.signInForm.value;

    const authReq: IAuthRequest = {
      clientId: environment.clientId,
      clientSecret: environment.clientSecret,
      grantType: environment.grantTypeSignIn,
      email: signInForm.email,
      password: signInForm.password
    };
    this.loginUser(authReq);
  }

  // Get Token from database
  loginUser(authReq: IAuthRequest) {
    const loginDate = new Date();
    this.isLoading = true;
    const user$: Observable<IUser> = this.authService.signIn(authReq).pipe(
      tap(auth => {
        const percentage = (auth.expiresIn / 100) * 5;
        const currentExpires = auth.expiresIn - percentage;
        const currentDate = new Date().setSeconds(currentExpires);
        const expires = new Date(currentDate).toString();
        localStorage.setItem('accountToken', auth.token);
        localStorage.setItem('refreshToken', auth.refreshToken);
        localStorage.setItem('loginDate', expires);
      }),
      switchMap(user => this.authService.getUser())
    );

    user$.subscribe(user => {
      take(1);
      localStorage.setItem('username', user.userName);
      this.checkAccountRole(user.roles);
    }, error => this.isLoading = false);
  }

  // Check account role to navigate module correct.
  checkAccountRole(roles: Array<string>) {
    roles.forEach((role: string) => {
      switch (role) {
        case 'Administrator':
          this.isLoading = false;
          this.router.navigate(['admin']);
          break;

        default:
          this.isLoading = false;
          break;
      }
    });
  }

  // Check form forgot on submit
  onSubmitForgotForm() {
    if (this.forgotForm.invalid) {
      return;
    }
    const forgotForm = this.forgotForm.value;
    this.sendEmail(forgotForm.email);
  }

  // Send email
  sendEmail(email: string) {
    this.isLoading = true;
    this.accountService.forgotPassword(email).subscribe(res => {
      this.isLoading = false;
      this.forgotPassword = false;
      this.matSnackBar.openFromComponent(SnackBarInfoComponent, {
        data: `Abbiamo inviato un email con il link per recuperare la password a ${email}`,
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
    }, error => this.isLoading = false);
  }
}
