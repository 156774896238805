import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {IAuth} from '../../interfaces/auth';
import IAuthRequest, {IAuthClientRequest} from '../../interfaces/auth-request';
import {IUser} from '../../interfaces/user';
import {IRefreshTokenRequest} from '../../interfaces/refresh-token-request';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootAuth;
  }

  signIn(authReq: IAuthRequest): Observable<IAuth> {
    return this.httpClient.post<IAuth>(this.url + 'Token', authReq);
  }

  getClientCredential(authReq: IAuthClientRequest): Observable<IAuth> {
    return this.httpClient.post<IAuth>(this.url + 'Token', authReq);
  }

  injectRefreshToken() {
    const accessToken: string = localStorage.getItem('accountToken');
    const refreshToken: string = localStorage.getItem('refreshToken');
    const refreshReq: IRefreshTokenRequest = {accessToken, refreshToken };
    return this.httpClient.post(`${this.url}RefreshToken`, refreshReq);
  }

  getUser(): Observable<IUser> {
    return this.httpClient.get<IUser>(this.url + 'getuser');
  }
}
